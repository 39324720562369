<template>
  <div class="home">
    <img src="@/assets/img/guangfa/home-bg.png" />
    <div class="content">
      <div class="logout" @click="logout">
        退出登录<img src="@/assets/img/guangfa/logout.png" />
      </div>
      <img class="homelogo" src="@/assets/img/guangfa/home-logo.png" />
      <a href="https://b.cgbchina.com.cn/">广发发现精彩兑换码兑换</a>
      <router-link tag="a" :to="{ name: 'ExchangePage' }">
        短信发放家乐福卡券
      </router-link>
    </div>
    <div class="logintips">
      如有疑问请咨询客服电话<a href="tel:400-882-6000">400-882-6000</a>
    </div>
  </div>
</template>

<script>
import { reqGuangfaLout,reqGuangfaCheckLogin } from '@/api/guangfa-project'
export default {
  name: 'GuanfaExchangeHome',
  data() {
    return {}
  },
  async created() {
    // 登录状态校验
    const data = await reqGuangfaCheckLogin()
    console.log(data)
  },
  methods: {
    async logout() {
      const data = await reqGuangfaLout()
      if (data && data.code === 0) {
        this.$router.push({ name: 'GuanfaLogin' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/guangfa.less';
</style>
